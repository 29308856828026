<template>
  <CreateAssetModelStyled>
    <header>Create Asset Model</header>
    <InputStyled :required="!$v.name.required">
      <input v-model.trim="$v.name.$model" :placeholder="$tc('assetModel')" />
    </InputStyled>
    <p class="errors" v-if="!$v.name.isUnique">{{ $tc('messages.notUnique') }}</p>
    <p class="errors" v-if="$v.name.$dirty && !$v.name.required">{{ $tc('messages.required') }}</p>
    <p class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</p>
    <Multiselect :options="machineTypes" :value="selectedAssetModel" :loading="$apollo.loading" @select="setassetModel" />
    <ButtonsGroup>
      <ButtonStyled :disabled="!$v.name.required || !$v.name.minLength || !$v.name.isUnique" @click="saveAssetModel">{{
        $tc('actions.create')
      }}</ButtonStyled>
      <ButtonStyled color="grey" @click="$root.$emit('closeOverlay')">{{ $tc('actions.cancel') }}</ButtonStyled>
    </ButtonsGroup>
  </CreateAssetModelStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import get from 'lodash/get'

import Multiselect from 'vue-multiselect'

import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'

import { buttonReset, flexCenter } from '@styles/mixins'

import ASSET_MODELS_QUERY from '#/graphql/assetModels/assetModels.gql'
import MACHINE_TYPES from '#/graphql/assetModels/MotionEnum.gql'
import CREATE_ASSET_MODEL_MUTATION from '#/graphql/assetModels/createAssetModel.gql'

const InputStyled = styled('div')`
  width: 100%;
  padding: 1rem 0;
  input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    color: ${props => props.theme.colors.white};
    background: ${props => chroma(props.theme.colors.white).alpha(0.1).css()};
    border-bottom: 1px solid
      ${props => (props.required ? chroma(props.theme.colors.red).alpha(0.8).css() : chroma(props.theme.colors.green).alpha(0.8).css())};
    transition: border-color 0.5s ease;

    &:focus {
      border-color: ${props => props.theme.colors.primary};
    }

    &::placeholder {
      color: ${props => chroma(props.theme.colors.white).alpha(0.5).css()};
    }
  }
`

const ButtonStyled = styled('button')`
  ${props => buttonReset(props)}
  ${flexCenter}
  margin: .5rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background: ${props => props.theme.colors.orange};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryLighter};
  }
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`

const ButtonsGroup = styled('div')`
  display: flex;
  flex-direction: row;
  float: right;
`

const CreateAssetModelStyled = styled('div')`
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;

  .errors {
    color: red;
  }
`
export default {
  components: {
    CreateAssetModelStyled,
    InputStyled,
    ButtonsGroup,
    ButtonStyled,
    Multiselect,
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(1),
      isUnique() {
        const found = this.assetModels.find(item => item.name.toLowerCase() === this.name.toLowerCase())
        if (found) {
          return false
        }
        return true
      },
    },
  },
  data() {
    return {
      name: '',
      motion: null,
      assetModels: [],
      selectedAssetModel: '',
      machineTypes: [],
    }
  },
  methods: {
    async saveAssetModel() {
      const data = {
        name: this.name,
      }
      if (this.selectedAssetModel) {
        data.motion = this.selectedAssetModel
      }
      await this.$apollo.mutate({
        mutation: CREATE_ASSET_MODEL_MUTATION,
        variables: {
          data,
        },
        update: (store, { data: { createMachineModel } }) => {
          let data = store.readQuery({
            query: ASSET_MODELS_QUERY,
          })
          data.assetModels.push(createMachineModel)
          store.writeQuery({
            query: ASSET_MODELS_QUERY,
            data,
          })
          this.$root.$emit('closeOverlay')
        },
      })
    },
    setassetModel(model) {
      this.selectedAssetModel = model
    },
  },
  apollo: {
    assetModels: {
      query: ASSET_MODELS_QUERY,
    },
    machineTypes: {
      query: MACHINE_TYPES,
      update({ machineTypes }) {
        return get(machineTypes, 'enumValues', []).map(t => t.name)
      },
    },
  },
}
</script>
